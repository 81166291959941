// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAyzDDlOEQ2SXOaWJBCyY_hn0PJh5aluYo",
    authDomain: "opennote-55740.firebaseapp.com",
    projectId: "opennote-55740",
    storageBucket: "opennote-55740.appspot.com",
    messagingSenderId: "502875437626",
    appId: "1:502875437626:web:108d80f1f59ef8462f5303"
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);