import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    calls: 0,
    accepted: 0,
    rejected: 0,
    ragCalls: 0,
    ragUpserts: 0
};

export const telemetrySlice = createSlice({
    name: "telemetry",
    initialState,
    reducers: {
        setCalls: (state, action) => {
            state.calls = action.payload;
        },
        setAccepted: (state, action) => {
            state.accepted = action.payload;
        },
        setRejected: (state, action) => {
            state.rejected = action.payload;
        },
        setRagCalls: (state, action) => {
            state.ragCalls = action.payload;
        },
        setRagUpserts: (state, action) => {
            state.ragUpserts = action.payload;
        }
    }
});

export const { setCalls, setAccepted, setRejected, setRagCalls, setRagUpserts } = telemetrySlice.actions;