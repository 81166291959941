import { combineReducers } from "redux";
import { dataSlice } from "./dataSlice";
import { userSlice } from "./userSlice";
import { noteSlice } from "./noteSlice";
import { telemetrySlice } from "./telemetrySlice";

export const rootReducer = combineReducers({
    data: dataSlice.reducer,
    user: userSlice.reducer,
    note: noteSlice.reducer,
    telemetry: telemetrySlice.reducer
});