import './Sidebar.css';

import React, { useState, useEffect } from 'react';

import Sidebar_Icon from '../Resources/Sidebar_Icon.png';
import User_Icon from '../Resources/User_Icon.png';
import logout_Icon from '../Resources/logout_icon.png';
import newnote_icon from '../Resources/newnote_icon.png';
import plugin_icon from '../Resources/plugin_icon.png';
import social_icon from '../Resources/social_icon.png';

import { useDispatch, useSelector } from 'react-redux';

import { setView } from '../State/dataSlice';
import { logoutUser } from "../State/userSlice";
import { setID, setCurrentNote, setNoteChanged } from "../State/noteSlice";
import { signOut } from "firebase/auth";

import { auth, db, app } from "../Auth/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

const Sidebar = ({ showLogin }) => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const dispatch = useDispatch();
    const view = useSelector((state) => state.root.view);

    const user = useSelector((state) => state.root.user.user);
    const [userNotes, setUserNotes] = useState([]);

    const noteID = useSelector((state) => state.root.note.noteID);
    const noteChanged = useSelector((state) => state.root.note.noteChanged);

    useEffect(() => {
        if (user) {
            const notesQuery = query(collection(db, 'notes'), where('userId', '==', user.uid));

            const unsubscribe = onSnapshot(notesQuery, (snapshot) => {
                const notes = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // invert the order of the notes
                notes.reverse();

                setUserNotes(notes);

                // add rows of for each note to the sidebar
                const container = document.querySelector('.notes-container');
                container.innerHTML = ''; // Clear the container
                for (let i = 0; i < notes.length; i++) {
                    let row = document.createElement('div');
                    row.classList.add('button-row');
                    let button = document.createElement('button');
                    button.classList.add('row-button');
                    button.classList.add('child-row-button');
                    button.innerHTML = i + 1;

                    button.dataset.id = notes[i].id;
                    button.dataset.text = notes[i].text;
                    button.addEventListener('click', onNoteClick);

                    let text = document.createElement('span');
                    text.classList.add('button-text');
                    text.innerHTML = notes[i].title;
                    row.appendChild(button);
                    row.appendChild(text); // Append the text to the row
                    container.appendChild(row);
                }
            });

            return () => unsubscribe(); // Clean up on unmount
        }
    }, [user]);

    // when note id is changed, update userNotes and the sidebar
    useEffect(() => {
        if (user) {
            const notesQuery = query(collection(db, 'notes'), where('userId', '==', user.uid));

            const unsubscribe = onSnapshot(notesQuery, (snapshot) => {
                const notes = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // invert the order of the notes
                notes.reverse();

                setUserNotes(notes);

                // add rows of for each note to the sidebar
                const container = document.querySelector('.notes-container');
                container.innerHTML = ''; // Clear the container
                for (let i = 0; i < notes.length; i++) {
                    let row = document.createElement('div');
                    row.classList.add('button-row');
                    let button = document.createElement('button');
                    button.classList.add('row-button');
                    button.classList.add('child-row-button');
                    button.innerHTML = i + 1;

                    button.dataset.id = notes[i].id;
                    button.dataset.text = notes[i].text;
                    button.addEventListener('click', onNoteClick);

                    let text = document.createElement('span');
                    text.classList.add('button-text');
                    text.innerHTML = notes[i].title;
                    row.appendChild(button);
                    row.appendChild(text); // Append the text to the row
                    container.appendChild(row);
                }
            });

            return () => unsubscribe(); // Clean up on unmount
        }
    }, [noteID]);
        

    const onNoteClick = (event) => {
        const id = event.target.dataset.id;
        const text = event.target.dataset.text;
        dispatch(setView('note'));
        dispatch(setID(id));
        dispatch(setCurrentNote(text));
        dispatch(setNoteChanged(!noteChanged));
    };

    const handelLogout = () => {
        setUserNotes([]);
        dispatch(setID(-1));
        dispatch(setCurrentNote(''));
        if(isSidebarOpen) {
            toggleSide();
        }
        dispatch(logoutUser());
        signOut(auth);
        showLogin();
        // Clear the notes container
        const container = document.querySelector('.notes-container');
        container.innerHTML = '';
    };

    function toggleSide() {
        if (isSidebarOpen) {
            document.querySelector('.Sidebar').classList.remove('show');
            document.querySelector('.main-container').classList.remove('show');
            document.querySelector('.notes-container').style.display = 'none';
            setIsSidebarOpen(false);
            return;
        } else {
            document.querySelector('.Sidebar').classList.add('show');
            document.querySelector('.main-container').classList.add('show');
            document.querySelector('.notes-container').style.display = 'block';
            setIsSidebarOpen(true);
            return;
        }
    }

    function newNote() {
        dispatch(setView('note'));
        dispatch(setNoteChanged(!noteChanged));
        dispatch(setID(-1));
        dispatch(setCurrentNote(''));
    }

    function goToMarket() {
        dispatch(setView('market'));
    }

    return (
        <div className="sidebar">
            <div className="button-row">
                <button className='row-button slide-button' type='button' onClick={toggleSide}>
                    <img src={Sidebar_Icon} alt="OpenNote" className="sidebar-icon" />
                </button>
                <h1 >OpenNote</h1>
            </div>
            <div className="button-row">
                <button className='row-button' onClick={newNote} alt= "Open Sidebar">
                    <img src={newnote_icon} alt="OpenNote" className="sidebar-icon" />
                </button>
                <span className='button-text'>New Note</span>
            </div>

            <div className="notes-container"></div>

            <div className="button-row">
                <button className='row-button' onClick={goToMarket}>
                    <img src={plugin_icon} alt="OpenNote" className="sidebar-icon" />
                </button>
                <span className='button-text'>Market</span>
            </div>
            <div className="button-row">
                <button className='row-button'>
                    <img src={social_icon} alt="OpenNote" className="sidebar-icon" />
                </button>
                <span className='button-text'>Social</span>
            </div>

            <div className="button-row bottom-row">
                <button className='row-button' type="button" onClick={handelLogout}>
                    <img src={logout_Icon} alt="OpenNote" className="sidebar-icon" />
                </button>
                <span className='button-text'>Logout of {useSelector((state) => state.root.user.user?.username)}</span>
            </div>
        </div>
    );
}

export default Sidebar;