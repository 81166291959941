import './Market.css';

import React, { useState, useEffect } from 'react';

// market page for OpenNote plugin market
const Market = () => {
    
        return (
            <div className='market'>
                <div className='market-header'>
                    <h1>OpenNote Plugin Market</h1>
                </div>
                <div className='market-body'>
                    <div className='market-plugin'>
                        <h2>Plugin 1</h2>
                        <p>Plugin 1 Description</p>
                        <button className='market-button'>Add</button>
                    </div>
                    <div className='market-plugin'>
                        <h2>Plugin 2</h2>
                        <p>Plugin 2 Description</p>
                        <button className='market-button'>Add</button>
                    </div>
                    <div className='market-plugin'>
                        <h2>Plugin 3</h2>
                        <p>Plugin 3 Description</p>
                        <button className='market-button'>Add</button>
                    </div>
                    <div className='market-plugin'>
                        <h2>Plugin 4</h2>
                        <p>Plugin 4 Description</p>
                        <button className='market-button'>Add</button>
                    </div>
                    <div className='market-plugin'>
                        <h2>Plugin 5</h2>
                        <p>Plugin 5 Description</p>
                        <button className='market-button'>Add</button>
                    </div>
                </div>
            </div>
        );
}

export default Market;