import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    noteID: localStorage.getItem('lastNoteID') ? localStorage.getItem('lastNoteID') : -1,
    currentNote: localStorage.getItem('lastNote') ? localStorage.getItem('lastNote') : '',
    noteChanged: false,
};

export const noteSlice = createSlice({
    name: 'note',
    initialState,
    reducers: {
        setID: (state, action) => {
            state.noteID = action.payload;
        },
        setCurrentNote: (state, action) => {
            state.currentNote = action.payload;
        },
        setNoteChanged: (state, action) => {
            state.noteChanged = action.payload;
        },
    },
});

export const { setID, setCurrentNote, setNoteChanged } = noteSlice.actions;