import './App.css';

import React, { useState, useEffect } from 'react';
import Modal from './Modal.js';
import LoginModal from '../Auth/LoginModal.js';
import Sidebar from '../Navigation/Sidebar.js';
import Market from './Market.js';
import NoteView from './NoteView.js';

import { useDispatch, useSelector } from 'react-redux';

import { app, auth, db } from '../Auth/firebase';
import { collection, addDoc, deleteDoc, doc, setDoc, query, where, onSnapshot, getDocs } from "firebase/firestore";
import { loginUser, setLoading } from '../State/userSlice';
import { setCalls, setAccepted, setRejected, setRagCalls, setRagUpserts } from '../State/telemetrySlice';

function App() {
  const [showTutorial, setShowTutorial] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const view = useSelector((state) => state.root.data.view);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.root.user.user);

  const telemetry = useSelector((state) => state.root.telemetry);

  const showModal = (type) => {
    switch (type) {
      case 'tutorial':
        setShowTutorial(true);
        break;
      case 'login':
        setShowLogin(true);
        break;
      default:
        setShowTutorial(true);
    }
  }

  const hideModal = (type) => {
    switch (type) {
      case 'tutorial':
        setShowTutorial(false);
        break;
      case 'login':
        setShowLogin(false);
        break;
      default:
        setShowTutorial(false);
    }
  }

  useEffect(() => {
    const setFullHeight = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      let elements = document.getElementsByClassName('full-height');
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.height = 'calc(var(--vh, 1vh) * 100)';
      }
    }

    window.addEventListener('resize', setFullHeight);
    window.addEventListener('load', setFullHeight);
    setFullHeight();

    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch(
          loginUser({
            uid: authUser.uid,
            username: authUser.displayName,
            email: authUser.email,
          })
        );
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        console.log("User is not logged in.");
      }
    });

  }, []);

  useEffect(() => {
    if (user) {
      showModal('tutorial')
      hideModal('login')

    } else {
      showModal('login')
      hideModal('tutorial')
    }
  }, [user]);

  // get monday of the current week
  function getMonday() {
    const date = new Date();
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
    const monday = new Date(date.setDate(diff));
    return `${monday.getMonth() + 1}/${monday.getDate()}/${monday.getFullYear()}`; // format date as mm/dd/yyyy
  }

  // Telemetry upload to firebase every 5 minutes
  useEffect(() => {
    const interval = setInterval(async () => {
      if (user) {
        console.log('uploading telemetry');
        console.log(JSON.stringify(telemetry));
        // check if document where userID = user.uid exists and the week is the current week
        const q = query(collection(db, "telemetry"), where("userID", "==", user.uid), where("week", "==", getMonday()));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size === 0) {
          // if not, create a new document
          const docRef = addDoc(collection(db, "telemetry"), {
            userID: user.uid,
            username: user.username,
            week: getMonday(),
            minutes: 5,
            calls: telemetry.calls,
            accepted: telemetry.accepted,
            rejected: telemetry.rejected,
            ragCalls: telemetry.ragCalls,
            ragUpserts: telemetry.ragUpserts
          });
        } else {
          // if it does, update the document
          querySnapshot.forEach((doc) => {
            setDoc(doc.ref, {
              userID: user.uid,
              username: user.username,
              week: getMonday(),
              minutes: doc.data().minutes + 5,
              calls: doc.data().calls + telemetry.calls,
              accepted: doc.data().accepted + telemetry.accepted,
              rejected: doc.data().rejected + telemetry.rejected,
              ragCalls: doc.data().ragCalls + telemetry.ragCalls,
              ragUpserts: doc.data().ragUpserts + telemetry.ragUpserts
            });
          });
          dispatch(setCalls(0));
          dispatch(setAccepted(0));
          dispatch(setRejected(0));
          dispatch(setRagCalls(0));
          dispatch(setRagUpserts(0));
        }
      }
    }, 300000);
    return () => clearInterval(interval);
  }, [user, telemetry]);

  return (
    <div className='app-container full-height'>
      <LoginModal className='modal' show={showLogin} handleClose={() => hideModal('login')} />
      <Modal className='modal' show={showTutorial} handleClose={() => hideModal('tutorial')}>
        <h2>OpenNote</h2>
        <p>OpenNote is a smart AI-powered note-taking app that gives you suggestions to complete your thoughts.</p>
        <p>Press <b>tab key</b> (or swipe right) to accept an entire suggestion</p>
        <p>Press crtl+rightarrow (or swipe left) to accept suggestion word by word</p>
        <p>Press crtl+downarrow to accept suggestion line by line</p>
      </Modal>
      <div className='main-container full-height'>
        <div className='Sidebar'>
          <Sidebar showLogin={() => showModal('login')} />
        </div>
        <div className='main-view'>
          {view === 'note' ? <NoteView showModal={() => showTutorial || showLogin} /> : <Market />}
        </div>
      </div>
    </div>
  );
}

export default App;