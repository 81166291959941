import './SmartTextArea.css';

import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';

import { setCalls, setAccepted, setRejected, setRagCalls, setRagUpserts } from '../State/telemetrySlice';

let backupNoteText = '';
let undoNoteText = '';
let undoCursorPosition = 0;

let editTextArea = document.querySelector('.editTextArea');
let suggestionTextArea = document.querySelector('.suggestion');

let hasSuggestion = false;

let cursorAtCall = 0;
let suggestionStart = 0;
let suggestionEnd = 0;

let cursorPosition = 0;

let keyCount = 0;

let called = false;

let isMobile = false;

const SmartTextArea = (props) => {
    const [note, setNote] = useState('');
    const [suggestion, setSuggestion] = useState('');

    const [mobileCursor, setMobileCursor] = useState(0);
    const [touchStartX, setTouchStartX] = useState(null);

    const dispatch = useDispatch();

    const currentNote = useSelector((state) => state.root.note.currentNote);
    const noteChanged = useSelector((state) => state.root.note.noteChanged);

    const user = useSelector((state) => state.root.user.user);

    const telemetry = useSelector((state) => state.root.telemetry);

    useEffect(() => {
        // get editTextArea and suggestionArea at the start
        editTextArea = document.querySelector('.editTextArea');
        suggestionTextArea = document.querySelector('.suggestion');

        // move cursor to the end of the editTextArea
        editTextArea.selectionStart = cursorPosition;
        editTextArea.selectionEnd = cursorPosition;
        setMobileCursor(cursorPosition);

        // check if device is mobile
        if (window.innerWidth <= 800) {
            isMobile = true;
        }

    }, []);

    // when currentNote changes
    useEffect(() => {
        setNote(currentNote);
        backupNoteText = currentNote;
        undoNoteText = backupNoteText;
        undoCursorPosition = editTextArea.selectionStart;
        setSuggestion(currentNote);
    }, [noteChanged]);

    useEffect(() => {
        // get editTextArea and suggestionArea at the start
        editTextArea = document.querySelector('.editTextArea');
        suggestionTextArea = document.querySelector('.suggestion');

        // set height of editTextArea to be the same as the scrollHeight of editTextArea
        editTextArea.style.height = 'auto';
        editTextArea.style.height = editTextArea.scrollHeight + 'px';

        // set height of suggestionArea to be the same as the scrollHeight of suggestionArea
        suggestionTextArea.style.height = 'auto';
        suggestionTextArea.style.height = suggestionTextArea.scrollHeight + 'px';

        // sets the suggestion to be the markdown if there is no suggestion
        if (!hasSuggestion) {
            setSuggestion(note);
        }
    }, [note, suggestion]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (keyCount < 12 && !hasSuggestion && document.activeElement === editTextArea && editTextArea.selectionStart === editTextArea.selectionEnd && !called && notMiddleOfWord() && note.length > 0) {
                cursorAtCall = editTextArea.selectionStart;
                called = true;
                getSuggestion('');
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [note, hasSuggestion]);

    useEffect(() => {
        // counts the number of keys pressed in the last second
        const timer2 = setInterval(() => {
            keyCount = 0;

        }, 1000);

        return () => clearInterval(timer2);
    }, [keyCount]);

    useEffect(() => {
        props.onChange(backupNoteText);
    }, [note, backupNoteText]);

    useEffect(() => {
        if (isMobile) {
            editTextArea.selectionStart = mobileCursor;
            editTextArea.selectionEnd = mobileCursor;
        }
    }, [mobileCursor]);

    function handleChange(event) {
        setNote(event.target.value);
        undoNoteText = backupNoteText;
        undoCursorPosition = editTextArea.selectionStart - 1;
        backupNoteText = event.target.value;
    }

    // makes a call to the api server
    async function callServer(params, route) {
        let { input, uid } = params;
        try {
            // change all & to %26
            input = input.replace(/&/g, "%26");
            const response = await axios.get('https://ons-gxn8x.ondigitalocean.app/' + route, {
                params: {
                    input: input,
                    uid: uid
                },
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                }
            });
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async function callOpenAI(c) {
        dispatch(setCalls(telemetry.calls + 1));

        const { selectionStart, selectionEnd, value } = editTextArea;
        const cursorPosition = cursorAtCall + c.length;
        const previousText = value.substring(cursorAtCall - 1000, cursorAtCall) + c;
        const newValue = value.substring(0, selectionStart) + c + value.substring(selectionEnd);
        const uid = user ? user.uid : '';

        await callServer({ input: previousText, uid: uid || '' }, "").then((response) => {
            if (editTextArea.selectionStart === cursorPosition) {
                hasSuggestion = true;
                suggestionStart = cursorPosition;
                suggestionEnd = cursorPosition + response.data.choices[0].text.length;
                const replacedString = response.data.choices[0].text.replace(/[^\n\t ]/g, ' ');

                setNote(newValue.substring(0, cursorPosition) + replacedString + newValue.substring(cursorPosition));
                setSuggestion(newValue.substring(0, cursorPosition) + response.data.choices[0].text + newValue.substring(cursorPosition));

                setTimeout(() => {
                    editTextArea.selectionStart = suggestionStart;
                    editTextArea.selectionEnd = suggestionStart;
                    setMobileCursor(suggestionStart);
                    called = false;
                }, 0);
            }
            else{
                dispatch(setRejected(telemetry.rejected + 1));
            }
            called = false;
        }).catch((error) => {
            console.error(error);
        });
    }

    async function callRAG(c, previousText) {
        dispatch(setRagCalls(telemetry.ragCalls + 1));
        
        const { selectionStart, selectionEnd, value } = editTextArea;
        const cursorPosition = cursorAtCall + c.length;
        const uid = user ? user.uid : '';

        await callServer({ input: previousText, uid: uid }, "ragquery").then((response) => {
            console.log(response);
            if (editTextArea.selectionStart === cursorPosition) {
                // ensure that the response is string by converting it to string
                const res = response.data.toString();
                // if response begins with any number or combinations of \n or \t or space then set prefix to that and remove it from response
                const prefix = res.match(/^[\n\t ]*/)[0];
                response.data = res.substring(prefix.length);
                const answer = prefix + "From Notes: " + res;
                hasSuggestion = true;
                suggestionStart = cursorPosition;
                suggestionEnd = cursorPosition + answer.length;
                const replacedString = answer.replace(/[^\n\t ]/g, ' ');

                setNote(value.substring(0, cursorPosition) + replacedString + value.substring(cursorPosition));
                setSuggestion(value.substring(0, cursorPosition) + answer + value.substring(cursorPosition));

                setTimeout(() => {
                    editTextArea.selectionStart = suggestionStart;
                    editTextArea.selectionEnd = suggestionStart;
                    setMobileCursor(suggestionStart);
                    called = false;
                }, 0);
            }else{
                dispatch(setRejected(telemetry.rejected + 1));
            }
            called = false;
        }).catch((error) => {
            console.error(error);
        });
    }

    function getSuggestion(c) {
        let previousText = note.substring(cursorAtCall - 1000, cursorAtCall);

        let lastRecallIndex = previousText.lastIndexOf('/recall');
        let fromNotesIndex = previousText.lastIndexOf('From Notes: ');

        if (lastRecallIndex !== -1) {
            if (fromNotesIndex !== -1 && fromNotesIndex > lastRecallIndex) {
                callOpenAI(c);
            } else {
                let textToRecall = previousText.substring(lastRecallIndex + 7);
                callRAG(c, textToRecall.trim());
            }
        } else {
            callOpenAI(c);
        }

    }

    function acceptSuggestion() {
        dispatch(setAccepted(telemetry.accepted + 1));

        setNote(suggestion);
        undoNoteText = backupNoteText;
        undoCursorPosition = editTextArea.selectionStart;
        backupNoteText = suggestion;
        hasSuggestion = false;

        setTimeout(() => {
            editTextArea.selectionStart = suggestionEnd;
            editTextArea.selectionEnd = suggestionEnd;
            setMobileCursor(suggestionEnd);
        }, 0);
    }

    function handleKeyDown(event) {
        keyCount++;

        if (event.key === 'Tab') {
            event.preventDefault();

            if (hasSuggestion) {
                acceptSuggestion();
            }
            else {
                const { selectionStart, selectionEnd, value } = event.target;

                const updatedNote =
                    value.substring(0, selectionStart) +
                    '\t' +
                    value.substring(selectionEnd);

                setNote(updatedNote);

                setTimeout(() => {
                    event.target.selectionStart = selectionEnd + 1;
                    event.target.selectionEnd = selectionEnd + 1;
                }, 0);
            }
        }

        else if (event.key === 'ArrowRight' && event.ctrlKey) {
            if (hasSuggestion) {
                dispatch(setAccepted(telemetry.accepted + 1));

                event.preventDefault();
                let i = suggestionStart;

                // find the next word
                while (suggestion[i] !== ' ' && i < suggestion.length || i === suggestionStart) {
                    i++;
                }

                // add next word to markdown if its within the unadded part of the suggestion
                if (i <= suggestionEnd + 1) {
                    setNote(backupNoteText.substring(0, suggestionStart) + suggestion.substring(suggestionStart, i) + suggestion.substring(i + 1, suggestionEnd).replace(/[^\n\t ]/g, ' ') + backupNoteText.substring(suggestionStart));

                    undoNoteText = backupNoteText;
                    undoCursorPosition = editTextArea.selectionStart;

                    backupNoteText = backupNoteText.substring(0, suggestionStart) + suggestion.substring(suggestionStart, i) + backupNoteText.substring(suggestionStart);

                    suggestionStart = i;

                    // set cursor position to after the word that was added
                    setTimeout(() => {
                        editTextArea.selectionStart = i;
                        editTextArea.selectionEnd = i;
                        setMobileCursor(i);
                    }, 0);
                }

                if (i === suggestionEnd) {
                    hasSuggestion = false;
                }
            }
        }

        else if (event.key === 'ArrowDown' && event.ctrlKey) {
            if (hasSuggestion) {
                dispatch(setAccepted(telemetry.accepted + 1));

                event.preventDefault();
                let i = suggestionStart;

                // find the next line
                while (suggestion[i] !== '\n' && i < suggestionEnd || i === suggestionStart) {
                    i++;
                }

                // add next line to markdown if its within the unadded part of the suggestion
                if (i <= suggestionEnd) {
                    setNote(backupNoteText.substring(0, suggestionStart) + suggestion.substring(suggestionStart, i) + suggestion.substring(i + 1, suggestionEnd).replace(/[^\n\t ]/g, ' ') + backupNoteText.substring(suggestionStart));

                    undoNoteText = backupNoteText;
                    undoCursorPosition = editTextArea.selectionStart;

                    backupNoteText = backupNoteText.substring(0, suggestionStart) + suggestion.substring(suggestionStart, i) + backupNoteText.substring(suggestionStart);

                    suggestionStart = i;

                    // set cursor position to after the line that was added
                    setTimeout(() => {
                        editTextArea.selectionStart = i;
                        editTextArea.selectionEnd = i;
                        setMobileCursor(i);
                    }, 0);
                }

                if (i === suggestionEnd) {
                    hasSuggestion = false;
                }
            }
        }

        else if (event.key === 'z' && event.ctrlKey) {
            event.preventDefault();
            backupNoteText = undoNoteText;
            setNote(undoNoteText);
            setSuggestion(undoNoteText);
            hasSuggestion = false;
            setTimeout(() => {
                editTextArea.selectionStart = undoCursorPosition;
                editTextArea.selectionEnd = undoCursorPosition;
                setMobileCursor(undoCursorPosition);
            }, 0);
        }

        else if (event.ctrlKey && event.key === 'c') {
            event.preventDefault();
            const { selectionStart, selectionEnd, value } = event.target;
            navigator.clipboard.writeText(value.substring(selectionStart, selectionEnd));
        }

        else if (event.ctrlKey && event.key === 'v') {
            event.preventDefault();
            navigator.clipboard.readText().then((clipText) => {
                const { selectionStart, selectionEnd } = event.target;
                let s = selectionStart;
                let e = selectionEnd;
                return (
                    setNote(backupNoteText.substring(0, s) + clipText + backupNoteText.substring(e)),
                    undoNoteText = backupNoteText,
                    undoCursorPosition = editTextArea.selectionStart,
                    backupNoteText = backupNoteText.substring(0, s) + clipText + backupNoteText.substring(e),
                    setSuggestion(backupNoteText.substring(0, s) + clipText + backupNoteText.substring(e)),
                    setTimeout(() => {
                        event.target.setSelectionRange(s + clipText.length, s + clipText.length);
                    }, 0)
                );
            });
        }

        else if (event.ctrlKey && event.key === 'x') {
            event.preventDefault();

            const { selectionStart, selectionEnd } = event.target;
            let s = selectionStart;
            let e = selectionEnd;
            navigator.clipboard.writeText(backupNoteText.substring(s, e));
            return (
                setNote(backupNoteText.substring(0, s) + backupNoteText.substring(e)),
                undoNoteText = backupNoteText,
                undoCursorPosition = editTextArea.selectionStart,
                backupNoteText = backupNoteText.substring(0, s) + backupNoteText.substring(e),
                setSuggestion(backupNoteText.substring(0, s) + backupNoteText.substring(e)),
                setTimeout(() => {
                    event.target.setSelectionRange(s, s);
                }, 0)
            );
        }

        else if (hasSuggestion) {
            const { selectionStart, selectionEnd } = event.target;
            let s = selectionStart;
            let e = selectionEnd;

            // handles deletion when multiple characters are selected
            if (s != e) {
                if (event.key !== 'Shift' && event.key !== 'Tab' && event.key !== 'Control' && event.key !== 'ArrowRight' && event.key !== 'ArrowDown') {
                    dispatch(setRejected(telemetry.rejected + 1));
                    if (e > backupNoteText.length) {
                        e = backupNoteText.length;
                    }
                    setNote(backupNoteText.substring(0, s) + backupNoteText.substring(e));
                    undoNoteText = backupNoteText;
                    undoCursorPosition = editTextArea.selectionStart;
                    backupNoteText = backupNoteText.substring(0, s) + backupNoteText.substring(e);
                    setSuggestion(backupNoteText.substring(0, s) + backupNoteText.substring(e));
                    if (event.key === 'Backspace') {
                        event.preventDefault();
                    }
                    hasSuggestion = false;
                }
            }

            // handles suggestion declining when a character that is not the first character of the suggestion is pressed
            else if (event.key !== suggestion[suggestionStart] || s !== suggestionStart) {
                if (event.key !== 'Shift' && event.key !== 'Tab' && event.key !== 'Control' && event.key !== 'ArrowRight' && event.key !== 'ArrowDown' && event.key !== 'ArrowUp' && event.key !== 'ArrowLeft' && event.key !== 'Alt' && event.key !== 'Escape') {
                    dispatch(setRejected(telemetry.rejected + 1));
                    if (s !== suggestionStart && s > suggestionStart && s < suggestionEnd) {
                        s = suggestionStart;
                    }
                    event.preventDefault();
                    setSuggestion(backupNoteText);
                    hasSuggestion = false;

                    let newNote;
                    if (event.key === 'Enter') {
                        newNote = backupNoteText.slice(0, s) + '\n' + backupNoteText.slice(s);
                    } else if (event.key === 'Backspace') {
                        newNote = backupNoteText.slice(0, s - 1) + backupNoteText.slice(s);
                    } else {
                        newNote = backupNoteText.slice(0, s) + event.key + backupNoteText.slice(s);
                    }

                    setNote(newNote);
                    setTimeout(() => {
                        event.target.setSelectionRange(s + (event.key === 'Backspace' ? -1 : 1), s + (event.key === 'Backspace' ? -1 : 1));
                    }, 0);
                }
            }

            else if ((event.key === suggestion[suggestionStart] || s === suggestionStart)) {
                suggestionStart++;
            }

        }

        // when enter or space is pressed, make openai call to get the next suggestion
        else if (event.key === 'Enter' && keyCount <= 15 && !hasSuggestion && !called) {
            cursorAtCall = editTextArea.selectionStart;
            called = true;
            getSuggestion('\n');
        }
        else if (event.key === ' ' && keyCount < 12 && !hasSuggestion && !called) {
            cursorAtCall = editTextArea.selectionStart;
            called = true;
            getSuggestion(' ');
        }
    }

    function handleTouchStart(event) {
        setTouchStartX(event.touches[0].clientX);
    }

    function handleTouchEnd(event) {
        if (touchStartX !== null) {
            const touchEndX = event.changedTouches[0].clientX;
            const swipeDistance = touchEndX - touchStartX;

            if (swipeDistance > 50) {
                // accept suggestion
                if (hasSuggestion) {
                    dispatch(setAccepted(telemetry.accepted + 1));

                    setNote(suggestion);
                    backupNoteText = suggestion;
                    hasSuggestion = false;
                    setTimeout(() => {
                        editTextArea.selectionStart = suggestionEnd;
                        editTextArea.selectionEnd = suggestionEnd;
                        setMobileCursor(suggestionEnd);
                    }, 0);
                }
            } else if (swipeDistance < -50) {
                if (hasSuggestion) {
                    event.preventDefault();
                    let i = suggestionStart;

                    // find the next word
                    while (suggestion[i] !== ' ' && i < suggestion.length || i === suggestionStart) {
                        i++;
                    }

                    // add next word to markdown if its within the unadded part of the suggestion
                    if (i <= suggestionEnd + 1) {
                        dispatch(setAccepted(telemetry.accepted + 1));

                        setNote(backupNoteText.substring(0, suggestionStart) + suggestion.substring(suggestionStart, i) + suggestion.substring(i + 1, suggestionEnd).replace(/[^\n\t ]/g, ' ') + backupNoteText.substring(suggestionStart));

                        backupNoteText = backupNoteText.substring(0, suggestionStart) + suggestion.substring(suggestionStart, i) + backupNoteText.substring(suggestionStart);

                        suggestionStart = i;

                        setTimeout(() => {
                            editTextArea.selectionStart = i;
                            editTextArea.selectionEnd = i;
                            setMobileCursor(i);
                        }, 0);
                    } else {
                        hasSuggestion = false;
                    }
                }
            }
            setTouchStartX(null);
        }
    }

    // checks if the cursor is in not the middle of a word
    function notMiddleOfWord() {
        const curs = editTextArea.selectionStart
        const text = editTextArea.value
        if (curs === 0) return true
        else if (curs === text.length) return true
        else if (text[curs] === ' ') return true
        else if (text[curs - 1] === ' ') return true
        // next character is new line
        else if (text[curs] === '\n') return true
        // previous character is new line
        else if (text[curs - 1] === '\n') return true
        else return false
    }

    return (
        <div className="overlay-container">
            <textarea className="editTextArea" value={note} onChange={handleChange} onKeyDown={handleKeyDown} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} autocomplete="off" autocorrect="off" placeholder='Type Notes here...'></textarea>
            <textarea readOnly={true} className="suggestion" value={suggestion}></textarea>
        </div>
    );
};

export default SmartTextArea;