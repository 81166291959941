import './LoginModal.css';

import React, { useState, useEffect } from 'react';

import Login from "./Login";
import Signup from "./Signup";

const LoginModal = ({ handleClose, show }) => {
    const [active, setActive] = useState("login");

    const showHideClassName = show ? "login-modal display-block" : "login-modal display-none";

    const handleChange = () => {
        setActive(active === "login" ? "signup" : "login");
    };

    return (
        <div className={showHideClassName}>
            <section className="login-modal-main">
                <div className="login-modal-content">
                    {/* <h1>Login</h1>
                    <form>
                        <div>
                            <label for="username">Username</label>
                            <input type="text" id="username" name="username" />
                        </div>
                        <div>
                            <label for="password">Password</label>
                            <input type="text" id="password" name="password" />
                        </div>
                        <input type="submit" value="Submit" onClick={handleClose} />
                    </form> */}

                    {/* NEW AUTH CHANGES */}

                    <div className="authenticate">
                        <div className="auth__left">
                            <img
                                src="logo192.png"
                                alt="Instagram Screenshots"
                            />
                            <h1 style={{ color: '#575e55' }}>OpenNote {active}</h1>
                        </div>
                        <div className="auth__right">
                            {active === "login" ? <Login handleClose={handleClose}/> : <Signup handleClose={handleClose}/>} 

                            <div className="auth__more">
                                <span>
                                    {active === "login" ? (
                                        <>
                                            Don't have an account?{" "}
                                            <button style={{color: '#267568'}} onClick={handleChange}>Sign Up</button>
                                        </>
                                    ) : (
                                        <>
                                            Have an account? <button style={{color: '#267568'}} onClick={handleChange}>Log in</button>
                                        </>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* END OF NEW AUTH CHANGES */}

                </div>
            </section>
        </div>
    );
};

export default LoginModal;