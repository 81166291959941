import './NoteView.css';
import React, { useState, useEffect, createRef } from 'react';
import SmartTextArea from './SmartTextArea';
import { collection, addDoc, deleteDoc, doc, setDoc, query, where, onSnapshot } from "firebase/firestore";
import { db } from '../Auth/firebase';
import { useDispatch, useSelector } from 'react-redux';

import { setID, setCurrentNote, setNoteChanged } from "../State/noteSlice";
import { current } from '@reduxjs/toolkit';

import { setRagUpserts } from '../State/telemetrySlice';

import axios from 'axios';

const NoteView = ({ showModal }) => {
    const [note, setNote] = useState('');

    const dispatch = useDispatch();
    const user = useSelector((state) => state.root.user.user);

    const noteID = useSelector((state) => state.root.note.noteID);
    const noteChanged = useSelector((state) => state.root.note.noteChanged);
    const currentNote = useSelector((state) => state.root.note.currentNote);

    const telemetry = useSelector((state) => state.root.telemetry);

    useEffect(() => {
        if (note !== '') {
            const interval = setInterval(() => {
                handleSave(false);
            }, 5000);
            return () => clearInterval(interval);
        }
    }, [note, noteID]);

    useEffect(() => {
        setNote(currentNote);
    }, [noteChanged]);

    useEffect(() => {
        window.onbeforeunload = (event) => {
            event.preventDefault();
            handleSave(false);
        };
    }, []);

    function handleNote(value) {
        setNote(value);
    }

    function handleDelete() {
        if (noteID !== -1) {
            const docRef = doc(db, "notes", noteID);
            deleteDoc(docRef);
        }
        dispatch(setID(-1));
        dispatch(setCurrentNote(''));
        dispatch(setNoteChanged(!noteChanged));

        axios.get('https://ons-gxn8x.ondigitalocean.app/ragdelete', {
            params: {
                noteID: noteID
            },
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
            }
        }).then((response) => {
            console.log(response);
        });
    }

    async function handleSave(button) {
        if (user) {
            if (noteID == -1) {
                try {
                    if (note === '') {
                        return;
                    }
                    const docRef = await addDoc(collection(db, "notes"), {
                        title: note.split(/\s+/).filter((word) => word.length > 3)[0],
                        text: note,
                        userId: user.uid, // assuming you have a user object with an uid property
                        timestamp: new Date(), // adds a timestamp
                    })
                    dispatch(setID(docRef.id));
                    dispatch(setCurrentNote(note));

                    if (button) {
                        axios.post('https://ons-gxn8x.ondigitalocean.app/ragupsert', {
                            params: {
                                input: note,
                                uid: user.uid,
                                noteID: noteID
                            },
                            headers: {
                                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                            }
                        }).then((response) => {
                            console.log(response);
                            dispatch(setRagUpserts(telemetry.ragUpserts + 1));
                        });
                    }

                } catch (e) {
                    console.error("Error adding document: ", e);
                }
            } else {
                const docRef = doc(db, "notes", noteID);
                await setDoc(docRef, {
                    title: note.split(/\s+/).filter((word) => word.length > 3)[0],
                    text: note,
                }, { merge: true });
                dispatch(setCurrentNote(note));

                if (button) {
                    axios.post('https://ons-gxn8x.ondigitalocean.app/ragupsert',
                        {
                            input: note,
                            uid: user.uid,
                            noteID: noteID
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                            }

                        }).then((response) => {
                            console.log(response);
                            dispatch(setRagUpserts(telemetry.ragUpserts + 1));
                        });
                }

            }

            localStorage.setItem('lastNoteID', noteID);
            localStorage.setItem('lastNote', note);
        }
    }

    function handleSaveButton() {
        handleSave(true);
    }

    return (
        <div className="note-view">
            <div className="note-page">
                <div className='action-bar'>
                    <button className="save-button" onClick={handleSave}>Save</button>
                    <button className="delete-button" onClick={handleDelete}>Delete</button>
                </div>
                <SmartTextArea className="smart-text-area" disabled={showModal} onChange={handleNote} save={handleSave} />
            </div>
        </div>
    )
}

export default NoteView;